import React, { useRef } from "react";
import Layout from "@components/Layout";
import BgImage from "gatsby-background-image";
import { graphql, useStaticQuery } from "gatsby";
import Video from "@components/Video";
import Slider from "react-slick";

import styles from "@views/TentangKami/about.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TentangKami = () => {
  const img = useStaticQuery(graphql`
    query AboutQuery {
      bgAboutDesktop: file(relativePath: { eq: "bg/desktop/bg-polos.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 500, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bgAboutMobile: file(relativePath: { eq: "bg/mobile/bg-polos.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 625, maxHeight: 1104, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      igIcon: file(relativePath: { eq: "icon/ig.svg" }) {
        publicURL
      }
      ytIcon: file(relativePath: { eq: "icon/yt.svg" }) {
        publicURL
      }
      arrowUp: file(relativePath: { eq: "icon/icon-arrow-up.svg" }) {
        publicURL
      }
      arrowDown: file(relativePath: { eq: "icon/icon-arrow-down.svg" }) {
        publicURL
      }
    }
  `);

  const sliderRef = useRef<Slider | null>(null);

  const bg = [
    img.bgAboutDesktop.childImageSharp.fluid,
    {
      ...img.bgAboutMobile.childImageSharp.fluid,
      media: "(max-width: 1023.98px)",
    },
  ];

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    vertical: true,
    arrows: false,
    swipe: false,
    verticalSwiping: false,
    swipeToSlide: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: styles.heroSlider,
  };

  return (
    <Layout noFooter>
      <BgImage fluid={bg} className={styles.bgAbout}>
        <Slider {...sliderSettings} ref={sliderRef}>
          <div className={styles.about}>
            <div className={styles.video}>
              <Video
                link="https://www.youtube.com/watch?v=epEB_llv9NY"
                quality="sd"
                imgStyle={{ borderRadius: 0 }}
              />
            </div>
            <div className={styles.title}>
              <h3>
                <span style={{ color: "#db9a09" }}>Siapa</span>kah Kami?
              </h3>
            </div>
            <div className={styles.textContainer1}>
              <p>
                Kami adalah konveksi bikin seragam online no 1 di Indonesia. Kami
                berdiri sejak tahun 2014 di Depok, Jawa Barat.
              </p>
              <p>
                Kami mempunyai misi untuk membahagiakan para pekerja dan juga
                komunitas, dengan menciptakan pakaian yang nyaman dikenakan
                dalam kegiatan sehari-hari.
              </p>
            </div>
            <div className={styles.textContainer2}>
              <div className={styles.left}>
                <p>
                  Berlokasi di Jakarta, kami adalah perusahaan konveksi Seragam
                  Kerja yang sudah berpengalaman selama bertahun-tahun menangani
                  kebutuhan seragam kerja. Kami memiliki tim costumer service
                  yang mampu merespon dengan cepat segala pertanyaan maupun
                  hal-hal yang ingin diketahui oleh klien. Dengan seleksi ketat
                  yang kami lakukan, kami telah memiliki penjahit – penjahit
                  seragam kerja yang memiliki kualitas jahitan rapi khas tailor.
                  Ditambah dengan tim quality control yang berpengalaman, kami
                  bisa menjamin dan memastikan bahwa barang yang dipesan sesuai
                  dengan keinginan klien baik, kerapian, ukuran maupun model
                  seragam jaket. Untuk menyelesaikan problem waktu penger- jaan
                  seragam kerja yang molor, kami telah membentuk tim manajemen
                  produksi yang tertata sehingga mampu mendeliver order klien
                  tepat waktu. Kami memiliki kapasitas produksi besar, sehingga
                  mampu melayani order banyak klien dalam waktu yang bersamaan.
                  Berkenaan dengan bahan material seragam kerja, kami memberikan
                  garansi bahan material maupun warna sesuai dengan yang
                  disepakati dengan klien di awal. Dan tentunya, kami bisa
                  memberikan harga yang terjangkau, tanpa mengurangi kualitas
                  bahan dan kualitas jahitan. Silakan hubungi CS kami, dengan
                  senang hati CS kami akan melayani serta menjawab segala
                  pertanyaan Anda.
                </p>
                <p className={styles.address}>
                  Workshop : Jalan Dewi Sartika No.38 Pancoran Mas Depok, Kota
                  Depok, Jawa Barat 16431
                </p>
              </div>
              <div className={styles.right}>
                <p>IKUTI KAMI DI</p>
                <div className={styles.socials}>
                  <a
                    href="https://www.youtube.com/channel/UCIMaY8YjCb9qisvLS9M27Uw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={img.ytIcon.publicURL} alt="youtube channel" />
                  </a>
                  <a
                    href="https://www.instagram.com/gudangseragam/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={img.igIcon.publicURL} alt="instagram account" />
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.nextSlide} onClick={nextSlide}>
              <img src={img.arrowUp.publicURL} />
              <span>Baca tentang kami</span>
            </div>
          </div>
          <div className={styles.aboutText}>
            <div className={styles.textContainer}>
              <p>
                Terletak di Jakarta, kami adalah perusahaan konveksi seragam
                yang memiliki pengalaman bertahun-tahun menangani kebutuhan
                seragam kerja, seragam kantor, seragam pabrik, ser- agam
                pertambangan / wearpack dan berbagai jenis seragam lainnya.Kami
                memiliki tim layanan pelanggan yang mampu merespons dengan cepat
                semua pertanyaan dan hal-hal yang ingin diketahui klien. Dengan
                pemilihan yang ketat yang kami lakukan, kami telah memiliki
                penjahit berseragam yang memiliki jahitan kualitas penjahit yang
                rapi. Ditambah dengan tim kontrol kualitas yang berpengalaman,
                kami dapat menjamin dan memastikan bahwa barang yang dipesan
                sesuai dengan keinginan klien, kerapian, ukuran dan model ser-
                agam. Untuk mengatasi masalah ketika bekerja pada bentangan
                seragam, kami telah membentuk tim manajemen produksi yang
                terorganisir dengan baik yang mampu mengirim- kan pesanan klien
                tepat waktu.
              </p>
              <p>
                Kami memiliki kapasitas produksi yang besar, sehingga untuk
                dapat melayani banyak klien dalam waktu bersamaan bahan yang
                seragam, kami memberikan jaminan material dan warna sesuai
                kesepakatan dengan klien di awal. Dan tentu saja, kami dapat
                memberikan harga yang terjangkau, tanpa mengurangi kualitas
                material dan kualitas jahitannya. Silakan hubungi CS kami, CS
                kami akan dengan senang hati melayani dan menjawab semua
                pertanyaan Anda. Spesialis Seragam Kerja. 1574++ Klien Puas.
                Tepat Waktu. Tempat dan Penjahit Sendiri. Mudah, Cepat,
                Bergaransi. Murah Berkualitas. Gratis Sampel. Gratis Pengiriman.
                Gratis Kunjungan. Gratis Desain. Produsen Langsung. Workshop
                Sendiri. Konveksi seragam ter- percaya, 1574++ Klien puas dan
                terus repeat order dari tahun ke tahun. Workshop milik sendiri
                sehingga Harga kompetitif, kontrol kualitas ketat, tepat waktu.
                4 orang customer service kami siap melayani kebutuhan Anda
                dengan cepat dan ramah.
              </p>
            </div>
            <div className={styles.nextSlide} onClick={prevSlide}>
              <img src={img.arrowDown.publicURL} />
              <span className={styles.back}>Kembali untuk menonton video</span>
            </div>
          </div>
        </Slider>
      </BgImage>
    </Layout>
  );
};

export default TentangKami;
